<template>
  <div class="home">
    <img alt="Nosh logo" src="../assets/logo.svg">
    <!-- <HelloWorld msg="Nosh"/> -->
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src

@Options({
  components: {
    HelloWorld,
  },
})
export default class Home extends Vue {}
</script>

<style scoped>
img {
  padding-top: 10rem;
  width: 80%;
}
</style>